<template>
  <div class="nav__toggler">
    <button
      class="nav__toggler-btn"
        @click="collapsed = !collapsed"
      >
        <Icon v-if="collapsed" name="arrow-right" />
        <Icon v-else name="arrow-left" />
      </button>
  </div>
</template>

<script setup>
import { ref, inject } from 'vue';
import Icon from '@/components/common/Icon.vue';
const collapsed = inject('collapsed');

</script>

<style lang="scss">
.nav {

  &__toggler {
   position: absolute;
   top: 16px;
   display: block;
   z-index: 103;
   margin-left: -10px;
   .icon {
      font-size: 18px;
      color: var(--secondary);
   }
  }

  &__toggler-btn {
    padding: 6px 6px 6px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: none;
    border: none;
    cursor: pointer;
    border-radius: 0 20px 20px 0;
    background-color: #ffffff;
    box-shadow: 2px 0 3px 0 #00000010;
    &:hover {
      background-color: #fff;
    }
  }
}
  </style>