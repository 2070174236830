import { getShops, getTaxes, getShopDetails } from '@/services/apiShops';

const state = () => ({
  shops: [],
  taxes: [],
  activeShop: null,
});

const getters = {
  shops: (state) => state.shops,
  taxes: (state) => state.taxes,
};


const actions = {
  async getShops({ commit, state }) {
    if (state.shops.length) {
      return state.shops;
    }

    const response = await getShops();
    commit('SET_SHOPS', response.data);
    return response.data;
  },

  async getShop({ commit, state }, id) {
    if (state.shops.length) {
      return state.shops.find((shop) => shop.id === id);
    }

    const response = await getShops();
    commit('SET_SHOPS', response.data);
    return response.data.find((shop) => shop.id === id);
  },

  async getShopDetails({ commit }, id) {
    const response = await getShopDetails(id);
    commit('SET_ACTIVE_SHOP', response);
    return response;
  },

  async fetchTaxes({ commit, state }) {
    if (state.taxes.length) {
      return state.taxes;
    }

    const response = await getTaxes();
    const taxes = response.data.map((tax) => ({
      id_tax: tax.id_tax,
      name: tax.tax_lang_en.name,
      rate: tax.rate
    }));
    commit('SET_TAXES', taxes);
    return taxes;
  },

  async getTax({ commit, state }, id) {
    if (state.taxes.length) {
      return state.taxes.find((tax) => tax.id_tax === id);
    }

    const response = await getTaxes();
    const taxes = response.data.map((tax) => ({
      id_tax: tax.id_tax,
      name: tax.tax_lang_en.name,
      rate: tax.rate
    }));
    commit('SET_TAXES', taxes);
    return taxes.find((tax) => tax.id_tax === id);
  }
};

// mutations
const mutations = {
  SET_SHOPS(state, shops) {
    state.shops = shops;
  },
  SET_TAXES(state, taxes) {
    state.taxes = taxes;
  },
  SET_ACTIVE_SHOP(state, shop) {
    state.activeShop = shop;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
