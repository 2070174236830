import { apiFetch } from "./apiFetch";

type QueryParams = {
  page?: string;
  perPage?: string;
  sortColumn?: string;
  sortDirection?: string;
  'searchColumn[]'?: string;
  'searchTerm[]'?: string;
  id_shop?: string;
  active?: string;
}

export async function getShops() {
  const url = `/api/shops`
  return await apiFetch(url, 'GET')
}

export async function getShopDetails(shopId: string) {
  const url = `/api/shops/${shopId}/details`
  return await apiFetch(url, 'GET')
}

export async function getSuppliers(query: QueryParams) {
  const queryParams = new URLSearchParams(query).toString()
  const url = `/api/suppliers?${queryParams}`
  return await apiFetch(url, 'GET')
}

export async function getSuppliersById(supplierId: string) {
  const url = `/api/suppliers/${supplierId}`
  return await apiFetch(url, 'GET')
}

export async function getCarriers(query: QueryParams) {
  const queryParams = new URLSearchParams(query).toString()
  const url = `/api/carriers?${queryParams}`
  return await apiFetch(url, 'GET')
}

export async function getTaxes() {
  const url = `/api/taxes`
  return await apiFetch(url, 'GET')
}