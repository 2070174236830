import { apiFetch } from './apiFetch';

const trafficRoute = '/api/traffic'

export async function getTrafficDelays() {
  const url = `${trafficRoute}/delays`
  return await apiFetch(url, 'GET')
}

export async function getTrafficDelaysByIds(ids: string) {
  const url = `${trafficRoute}/delays-by-ids?ids=${ids}`
  return await apiFetch(url, 'GET')
}

export async function getTrafficComplaints() {
  const url = `${trafficRoute}/complaints`
  return await apiFetch(url, 'GET')
}

export async function getTrafficComplaintsDepartments() {
  const url = `${trafficRoute}/complaints-departments`
  return await apiFetch(url, 'GET')
}

export async function getTrafficComplaintsDepartmentTypes(departmentName: string) {
  const url = `${trafficRoute}/complaints-departments-types?departmentName=${departmentName}`
  return await apiFetch(url, 'GET')
}

export async function createTrafficComplaint(body: object) {
  const url = `${trafficRoute}/complaint`
  const data = await apiFetch(url, 'POST', body)
  return { note: data, status: 200 }
}

export async function getTrafficSnoozedDelays() {
  const url = `${trafficRoute}/snoozed-delays`
  return await apiFetch(url, 'GET')
}

export async function addTrafficSnoozedDelay(body: object) {
  const url = `${trafficRoute}/add-snoozed-delay`
  const data = await apiFetch(url, 'POST', body)
  return { note: data, status: 200 }
}

export async function getTrafficEmailsSent() {
  const url = `${trafficRoute}/traffic-emails-sent`
  return await apiFetch(url, 'GET')
}

export async function getTrafficUsers() {
  const url = `${trafficRoute}/traffic-users`
  return await apiFetch(url, 'GET')
}

export async function getTrafficDelayLogsByOrderDetailId(orderDetailId: string) {
  const url = `${trafficRoute}/traffic-delay-logs-by-order-detail-id?orderDetailId=${orderDetailId}`
  return await apiFetch(url, 'GET')
}

export async function addTrafficDelayMeta(body: object) {
  const url = `${trafficRoute}/traffic-delay-meta`
  const data = await apiFetch(url, 'POST', body)
  return { note: data, status: 200 }
}

export async function addTrafficDelayLog(body: object) {
  const url = `${trafficRoute}/delay-log`
  const data = await apiFetch(url, 'POST', body)
  return { note: data, status: 200 }
}

export async function emailToSupplierQueue(body: object) {
  const url = `${trafficRoute}/email-to-supplier-queue`
  const data = await apiFetch(url, 'POST', body)
  return { note: data, status: 200 }
}

export async function emailToCustomerQueue(body: object) {
  const url = `${trafficRoute}/email-to-customer-queue`
  const data = await apiFetch(url, 'POST', body)
  return { note: data, status: 200 }
}

export async function delaysUnassign() {
  const url = `${trafficRoute}/delays-unassign`
  const data = await apiFetch(url, 'POST')
  return { note: data, status: 200 }
}