import { formatPrice } from '@/helpers/format';

export function getGpm(sale, purchase, carrier, currency) {
  return formatPrice(
    (parseFloat(sale) - parseFloat(purchase) - parseFloat(carrier)).toFixed(2),
        currency
    ) + ' (' + getGpmPercentage(sale, purchase, carrier) + '%' + ')'
}

function getGpmPercentage(sale, purchase, carrier) {
  const gpm = (parseFloat(sale) - parseFloat(purchase) - parseFloat(carrier)) / parseFloat(sale) * 100
  return gpm.toFixed(2) > 0 ? gpm.toFixed(2) : 0
}