import { createStore } from 'vuex'

import quotes from './modules/quotes'
import alerts from './modules/alerts'
import delays from './modules/delays'
import shops from './modules/shops'
import orders from './modules/orders'
import allCustomers from './modules/customers'
import complaints from './modules/complaints'

export default createStore({
  namespaced: true,
  modules: {
    quotes,
    alerts,
    delays,
    shops,
    allCustomers, // this needs to be renamed and customers needs to be cleaned from this file
    orders,
    complaints
  },
  state: {
    user: null,
    customers: {},
    notes: {},
    conversations: [],
    messages: {},
    lastCustomer: null,
    queryResults: [],
    quotesFilterModel: null
  },

  mutations: {
    SET_USER(state, user) {
      state.user = user
      if (!user) {
        state.customers = {}
      }
    },
    SET_QUOTES_FILTER_MODEL(state, filterModel) {
      state.quotesFilterModel = filterModel;
      localStorage.setItem('quotesFilterModel', JSON.stringify(filterModel));
    },
    CLEAR_QUOTES_FILTER_MODEL(state) {
      state.quotesFilterModel = null;
      localStorage.removeItem('quotesFilterModel');
    },
  },

  actions: {
    setUser({ commit }, user) {
      commit('SET_USER', user)
    },

    loadQuotesFilterModel({ state, commit }) {
      const quotesFilterModel = JSON.parse(localStorage.getItem('quotesFilterModel')) || null
      commit('SET_QUOTES_FILTER_MODEL', quotesFilterModel)
    }
  },

  getters: {
    user: (state) => state.user,
  },
})