<template>
  <div  class="el-alert__wrapper">
    <TransitionGroup name="list" tag="div">
      <template v-for="(alert, index) in alerts" :key="index">
        <div class="small-notice" v-if="alert.type == 'notice'">
          {{ alert.message }}
        </div>
        <el-alert
          v-else
          :type="alert.type"
          :description="alert.message"
          @close="store.dispatch('alerts/removeAlert', index)"
          effect="dark"
          show-icon
        ></el-alert>
      </template>
    </TransitionGroup>
  </div>
</template>
<script setup>
import { useStore } from 'vuex'
import { computed, watch } from 'vue'
const store = useStore()

const alerts = computed(() => store.state.alerts.alerts)

</script>

<style lang="scss" scoped>
.el-alert {
  margin-bottom: 6px;
  &__wrapper {
    position: absolute;
    bottom: 20px;
    right: 0;
    margin: auto;
    left: 0;
    z-index: 999;
    width: 80%;
  }
}
.list-enter-active,
.list-leave-active {
  transition: all 0.5s ease;
}
.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateX(30px);
}

.small-notice {
  position: fixed;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  color: #fff;
  background-color: #ccc;
  padding: 4px 6px;
  font-size: 10px;
  border-radius: 6px;
}
</style>