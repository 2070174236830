import logo from '@/assets/john.svg'

const siteDefaults = {
  behavior: {
    openChatOnResponse: true,
    showAlertInTitleBar: 'New message',
    showResponseInTitleBar: true,
    displayFooter: true,
    toolToggleAvailable: false,
    settingsToggleAvailable: false,
   },
   texts: {
    headerTitle: 'John',
    headerTitleSub: 'AI bot',
    initialMessage: 'Hey, how may I help you?',
    inputPlaceholder: 'Type your message...',
    emailCaptureTitle: "Let's get started",
    nameLabel: 'Your name',
    nameInput: 'What is your name?',
    emailLabel: 'Your email',
    startConversation: 'Start a conversation',
    endConversation: 'End the conversation',
   },
   styles: {
    font: 'Helvetica, Arial, sans-serif',
    fontSize: '14px',
    rgb: 'rgba(0, 75, 120, 1)',
    backgroundRGB: 'rgba(255, 255, 255, 1)',
    contrastRGB: 'rgba(255, 255, 255, 1)',
    zIndex: 5000,
   }
 };

 const nudgeSiteSettings = {
   texts: {
     nudgeMessage: "Anything I can help you with?",
     nudgeChoices: [],
   },
   behavior: {
     enabled: true,
   },
 }
 const siteComponentStyles = {
   header: {
    background: 'rgba(0, 75, 120, 1)',
    borderBottom: '1px solid rgba(221, 221, 221, 1)',
    color: 'rgba(255, 255, 255, 1)',
    colorSubTitle: 'rgba(255, 255, 255, 0.5)',
    subTitleDisplay: 'block',
    font: 'Helvetica, Arial, sans-serif',
    iconBackground: 'rgba(255, 255, 255, 0.1)',
    svgPadding: '2px',
    iconStrokeColor: 'rgba(233, 229, 236, 0.1)',
    iconFill: 'rgba(255, 255, 255, 1)',
   },
   messages: {
     ai: {
      avatar: logo,
      background: 'rgba(240, 246, 255, 1)',
      color: 'rgba(0, 0, 0, 0.7)',
      border: 'none',
     },
     tool: {
      avatar: logo,
      background: 'rgba(42, 1, 79, 0.2)',
      color: 'rgba(42, 1, 79, 0.7)',
      border: 'none',
    },
     font: 'Helvetica, Arial, sans-serif',
     fontSize: '14px',
     fontWeight: 'normal',
     fontLetterSpacing: 'initial',
     fontLineHeight: 'initial',
     borderRadius: '5px',
    user: {
        background: 'rgba(247, 247, 247, 1)',
        color: 'rgba(0, 0, 0, 0.7)',
        border: 'none',
      },
    },
    input: {
      fontSize: '14px',
      borderTop: '1px solid rgba(221, 221, 221, 1)',
      background: 'rgba(255, 255, 255, 1)',
      color: 'rgba(42, 1, 79, 1)',
    },
    submitButton: {
      background: 'transparent',
      backgroundClick: 'rgba(42, 1, 79, 1)',
      backgroundHover: 'rgba(42, 1, 79, 0.1)',
      colorClick: 'rgba(255, 255, 255, 1)',
      color: 'rgba(42, 1, 79, 1)',
    },
    footer: {
      color: 'rgba(42, 1, 79, 0.1)',
      colorHover: 'rgba(42, 1, 79, 1)',
    },
 }

 const widgetToken = '55b304e8-3ed1-40b7-a62d-6de30dde6a06'

const initEngaige = () => {
  const engaigeSrc = document.createElement('script');
  engaigeSrc.src = 'https://engaige-widget.vercel.app/engaige.js';
  engaigeSrc.onload = function() {
    Engaige.init(
      widgetToken,
      siteDefaults,
      nudgeSiteSettings,
      siteComponentStyles
    );
  }
  document.head.appendChild(engaigeSrc);
}

const sendMessage = (li) => {
   if (Engaige) {
     var liContent = li.textContent;
     Engaige.api.sendMessage(liContent);
   } else {
     initEngaige();
     sendMessage(li)
   }
 }

 initEngaige();