import { apiFetch } from "./apiFetch";

type QueryParams = {
  page?: string;
  perPage?: string;
  sortColumn?: string;
  sortDirection?: string;
  'searchColumn[]'?: string;
  'searchTerm[]'?: string;
  id_customer?: string;
  id_order?: string;
  id_order_detail?: string;
}

export async function getComplaints(query: QueryParams) {
  const queryParams = new URLSearchParams(query).toString()
  const url = `/api/complaints/order-detail-complaints?${queryParams}`
  return await apiFetch(url, 'GET')
}

export async function getComplaintsByCustomer(query: QueryParams) {
  const queryParams = new URLSearchParams(query).toString()
  const url = `/api/complaints?${queryParams}`
  return await apiFetch(url, 'GET')
}
