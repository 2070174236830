import {
  getQuotes,
  getOrderStates,
} from "@/services/apiOrders";

import {
  getShops,
  getSuppliers,
  getCarriers
} from '@/services/apiShops';

import {
  formatDate,
  formatPrice,
  formatLabel,
  handlePriceColumnFilter,
  handleDefaultColumnFilter,
  handleDateColumFilter,
} from '@/helpers/format';

import { getGpm } from '@/helpers/gtm';

const state = () => ({
  rowData: [],
  orderStates: [],
  shops: [],
  suppliers: [],
  carriers: []
});

const getters = {
  getRowData: (state) => {
    state.rowData;
  },
};


const actions = {

  async fetchOrderStates({ commit, rootGetters }) {
    let user = rootGetters['user'];
    while (!user) {
      await new Promise(resolve => setTimeout(resolve, 500));
      user = rootGetters['user'];
    }
    const orderStatesResponse = await getOrderStates({page: 1, perPage: 1000})
    const orderStates = orderStatesResponse.data.reduce((acc, item) => {
      acc[item.id_order_state] = item.order_state_code?.replace(/_/g, ' ');
      return acc;
    }, {})
    if(!Object.keys(orderStates).length > 0) { return }
    
    let orderStatesByName = {}
    Object.keys(orderStates).forEach((key) => {
      if (!!orderStates[key]) {
        if(orderStatesByName[orderStates[key]]) {
          orderStatesByName[orderStates[key]].push(key)
        } else {
          orderStatesByName[orderStates[key]] = [key]
        }
      }

    })
    orderStatesByName = Object.keys(orderStatesByName).map((key) => {
      return {
        value: orderStatesByName[key],
        name: key
      }
    });

    commit("SET_ORDER_STATES", orderStatesByName);
    
  },

  async fetchRowData({ commit }, req) {
    const [
        shopsResponse,
        suppliersResponse,
        orderStatesResponse,
        quotesResponse
      ] = await Promise.all([
        getShops(),
        getSuppliers(),
        getOrderStates({page: 1, perPage: 1000}),
        getQuotes(getParams(req.params))
      ])

      commit("SET_SHOPS", shopsResponse.data);

      const shops = shopsResponse.data.reduce((acc, item) => {
        acc[item.id_shop] = item.name;
        return acc;
      }, {})

      const suppliers = suppliersResponse.data.reduce((acc, item) => {
        acc[item.id_supplier] = item.name;
        return acc;
      } , {})

      const orderState = orderStatesResponse.data.reduce((acc, item) => {
        acc[item.id_order_state] = item.order_state_code?.replace(/_/g, ' ');
        return acc;
      }, {})

      const rowData = await mapResponse(quotesResponse, shops, orderState, suppliers, orderStatesResponse);
      commit("SET_ROW_DATA", rowData);
  },

  async fetchSuppliers({ commit }) {
    const suppliersResponse = await getSuppliers();
    commit("SET_SUPPLIERS", suppliersResponse.data);
  },

  async fetchShops({ commit }) {
    const shopsResponse = await getShops();
    commit("SET_SHOPS", shopsResponse.data);
  },

  async fetchFilteredSuppliers({ commit }) {
    const suppliersResponse = await getSuppliers({active: 1});
    commit("SET_FILTERED_SUPPLIERS", suppliersResponse.data);
  },

  async fetchFilteredCarriers({ commit }) {
    const carriersResponse = await getCarriers({active: 1, deleted: 0});
    commit("SET_FILTERED_CARRIERS", carriersResponse.data);
  }
};

// mutations
const mutations = {

  SET_ROW_DATA(state, rowData) {
    state.rowData = rowData;
  },

  SET_ORDER_STATES(state, orderStates) {
    state.orderStates = orderStates;
  },

  SET_SUPPLIERS(state, suppliers) {
    state.suppliers = suppliers;
  },

  SET_SHOPS(state, shops) {
    state.shops = shops;
  },

  SET_FILTERED_SUPPLIERS(state, suppliers) {
    state.suppliers = suppliers;
  },

  SET_FILTERED_CARRIERS(state, carriers) {
    state.carriers = carriers;
  }

};

// this should probably be helpers too ...
async function mapResponse(quotesResponse, shops, orderStates, suppliers, orderStatesResponse) {
  quotesResponse.data = await quotesResponse.data.map((row) => ({
    id_order: row.id_order,
    customer: {email: row.customer.email, id: row.customer.id_customer},
    employee: row.employee?.name,
    email: row.customer.email,
    status: formatLabel(orderStates[row.current_state] ?? '-', row.current_state),
    state: orderStatesResponse.data.filter((item) => item.id_order_state === row.current_state)[0],
    date_add: formatDate(row.date_add),
    date_upd: formatDate(row.date_upd),
    daysUntilExpiration: row.days_till_expired ?? '-',
    totalPrice: formatPrice(row.total_paid_tax_excl, row.currency.sign),
    shop: shops[row.id_shop],
    orderDetails: row.order_detail.map(item => ({
      orderItemId: item.id_order_detail,
      productName: item.combination_path,
      salesPrice: formatPrice(item.total_price_tax_incl, row.currency.sign),
      purchasePrice: formatPrice(item.purchase_supplier_price, row.currency.sign),
      carrierPrice: formatPrice(item?.order_detail_extra?.carrier_purchase_price, row.currency.sign),
      gpm: getGpm(
          item.total_price_tax_excl,
          item.purchase_supplier_price,
          item?.order_detail_extra?.carrier_purchase_price,
          row.currency.sign
      ),
      supplier: formatLabel(
          suppliers[item.order_detail_extra?.id_supplier ?? ''] ?? '',
          item.order_detail_extra?.id_supplier ?? ''
      ),
      quantity: item.product_quantity,
      status: formatLabel(orderStates[item?.order_detail_extra?.id_order_state] ?? '-', item?.order_detail_extra?.id_order_state),
      targetDeliveryDate: formatDate(item?.order_detail_extra?.target_delivery_date),
    }))
  }))
  return quotesResponse;
}

function getParams(params) {
  const perPage = params.request.endRow - params.request.startRow
  const page = params.request.startRow / perPage + 1

  let searchColumns = [];
  let searchTerms = [];

  if (params.request.filterModel) {
    searchColumns = Object.keys(params.request.filterModel);
    searchTerms = searchColumns.map(column => {
      const filterModel = params.request.filterModel[column];
      switch (column) {
        case 'date_add':
        case 'date_upd':
          return handleDateColumFilter(filterModel);
        case 'totalPrice':
          return handlePriceColumnFilter(filterModel);
        default:
          return handleDefaultColumnFilter(filterModel);
      }
    })
  }
  return {
    perPage,
    page,
    sortColumn: params.request.sortModel[0]?.colId,
    sortDirection: params.request.sortModel[0]?.sort,
    'searchColumn[]': searchColumns.map(column => column),
    'searchTerm[]': searchTerms.map(term => encodeURIComponent(term)),
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
