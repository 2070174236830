export function formatNumbers(input: any, decimals: any = 2) {

    // Handle non-number cases
    if (input === undefined || input === null) return '0';

    let number = parseFloat(input); // This will handle strings, floats, and integers

    // Handle NaN (e.g., when a non-numeric string is passed)
    if (isNaN(number)) return 'unknown';

    // Convert to positive if negative
    number = Math.abs(number);

    // Format with commas
    return number.toFixed(decimals).replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}

export const formatDate = (date: string | null) => date ? new Date(date).toDateString() : '-'

export const formatPrice = (price: string | null, currency: string) => {
    if (price === null) {
        return '';
    }

    if (!currency) {
        currency = '€';
    }

    const formattedPrice = parseFloat(price).toLocaleString('nl-NL', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    });

    return `${formattedPrice} ${currency}`;
}
export const formatLabel = (name: string, id: number) => `${name} (${id})`

export const handleDateColumFilter = (filterModel) => {
    const { startDate, endDate } = filterModel;
    return (startDate || endDate) ? `${startDate}|${endDate}` : '';
}

export const handlePriceColumnFilter = (filterModel) => {
    const { minPrice, maxPrice } = filterModel;
    return (minPrice || maxPrice) ? `${minPrice}|${maxPrice}` : '';
}

export const handleDefaultColumnFilter = (filterModel) => {
    const { filter, value } = filterModel;
    return filter !== undefined ? filter : value;
}
export const groupOrderStatesByName = (orderStates: (string | null)[]) => {
    const map = new Map();
    orderStates.forEach((state, index) => {
        if (!state) {
            return;
        }

        const item = {name: state, value: index.toString()};
        const itemAlreadyMapped = map.get(item.name);
        if (itemAlreadyMapped) {
            itemAlreadyMapped.value = `${itemAlreadyMapped.value}, ${item.value}`;
        } else {
            map.set(item.name, item);
        }
    });
    return Array.from(map.values());
}
