const state = () => ({
  alerts: [],
});

const getters = {
  alerts: (state) => state.alerts,
};


const actions = {
  addAlert({ commit, state }, alert) {
    commit('ADD_ALERT', alert);
    setTimeout(() => {
      commit('REMOVE_ALERT', state.alerts.length - 1);
    }, 3000);
  },
  removeAlert({ commit }, index) {
    commit('REMOVE_ALERT', index);
  },
  clearAlerts({ commit }) {
    commit('CLEAR_ALERTS');
  }
};

// mutations
const mutations = {
  ADD_ALERT(state, alert) {
    state.alerts.push(alert);
  },
  REMOVE_ALERT(state, index) {
    state.alerts = state.alerts.filter((alert, i) => i !== index);
  },
  CLEAR_ALERTS(state) {
    state.alerts = [];
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
