<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'

const store = useStore()
let user = computed(() => store.state.user)

</script>

<template>
  <el-main class="page">
    <img class="logo" src="../assets/logo-360-blue.svg" alt="">
    <div class="hello">Welcome, <b>{{ user?.name }}</b></div>
  </el-main>
</template>


<style scoped>

.logo {
    width: 120px;
    margin-top: -50px;
}
.hello {
  font-size: 24px;
  font-weight: 300;
}
.hello b {
  font-weight: 500;
}
</style>